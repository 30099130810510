import every from 'lodash-es/every';
import isNil from 'lodash-es/isNil';
import isNumber from 'lodash-es/isNumber';
import isString from 'lodash-es/isString';
import some from 'lodash-es/some';
import sum from 'lodash-es/sum';
import zip from 'lodash-es/zip';
import { v4 as uuidv4 } from 'uuid';
import { Language } from '../model/language';

const isNumberOrSpaceRegex = /[\s\d]/;
const numberWords: string[] = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
  'hundred',
  'thousand',
  'million',
  'billion',
  'trillion'
];

const forbiddenWordPatterns = ['fuck', 'f***'];

const archaicFormRegex = /archaic (?:form|spelling) of ((?:\w|\s|-)*)/i;
const commonMisspellingRegex = /common misspelling of ((?:\w|\s|-)*)/i;
const obsoleteSpellingRegex = /obsolete spelling of ((?:\w|\s|-)*)/i;

const thirdPersonSingularRegex =
  /Third-person singular simple present indicative form of ((?:\w|\s|-)*)/i;
const pluralFormOf = /Plural form of ((?:\w|\s|-)*)/i;
const simplePresentOfRegex =
  /Simple past tense and past participle of ((?:\w|\s|-)*)/i;

const sameCharacterRepeatedThrice = /(.)\1{2,}/i;
const phraseWithTheSameCharacter = /^(.)\1+$/i;
const isEmpty = /^$/;
const nonAlphabet = /[^a-z]/i;

const forbiddenPhraseRegexes = [
  sameCharacterRepeatedThrice,
  phraseWithTheSameCharacter,
  isEmpty
];

const forbiddenExplanationRegexes = [
  archaicFormRegex,
  commonMisspellingRegex,
  obsoleteSpellingRegex
];

const correctSpellingOrBaseFormRegexes = [
  archaicFormRegex,
  commonMisspellingRegex,
  obsoleteSpellingRegex,
  thirdPersonSingularRegex,
  pluralFormOf,
  simplePresentOfRegex
];

export const apostrophes = '[\'`´‘’]';
export const apostrophesRegex = new RegExp(apostrophes, 'g');

export function getEnglishBaseFormWithCorrectSpelling(
  explanation: string
): string {
  for (
    let index = 0;
    index < correctSpellingOrBaseFormRegexes.length;
    index++
  ) {
    const regex = correctSpellingOrBaseFormRegexes[index];
    const results = regex.exec(explanation);
    if (results) {
      return results[1].trim();
    }
  }
  return '';
}

export function containsForbiddenExamplePattern(input: string): boolean {
  if (!input) return false;
  const loweredInput = input.toLowerCase();
  return some(
    forbiddenWordPatterns,
    (w: string) => loweredInput.indexOf(w) > -1
  );
}

export function containsForbiddenPhrasePattern(
  input: string,
  language: Language
): boolean {
  switch (language) {
    case Language.English:
      if (isSingleNonAlphabet(input)) return true;
      if (containsTwoNumberWords(input)) return true;
      if (containsForbiddenExamplePattern(input)) return true;
      return some(forbiddenPhraseRegexes, (r: RegExp) => r.test(input));
    default:
      return false;
  }
}

function isSingleNonAlphabet(input: string) {
  if (isNil(input)) return false;
  if (input.length !== 1) return false;
  return nonAlphabet.test(input);
}

function containsTwoNumberWords(input: string): boolean {
  if (!input) return false;
  const words = input.split(' ');
  let numberWordsCount = 0;
  for (let index = 0; index < words.length; index++) {
    const word = words[index];
    if (some(numberWords, (w: string) => w === word.toLowerCase())) {
      numberWordsCount++;
    }
    if (numberWordsCount > 1) {
      return true;
    }
  }
  return false;
}

export function matchesForbiddenExplanationRegex(input: string): boolean {
  return some(forbiddenExplanationRegexes, (r: RegExp) => r.test(input));
}

export function replaceApostropheWithApostrophePattern(input: string) {
  return input.replace(apostrophesRegex, apostrophes);
}

export function generateUUID(): string {
  return uuidv4();
}
export function isNilOrWhiteSpace(value: string): boolean {
  return isNil(value) || value.trim() === '';
}

export function isNumberOrEmpty(value: string): boolean {
  if (isNilOrWhiteSpace(value)) {
    return true;
  }
  return every(value, (c) => isNumberOrSpaceRegex.test(c));
}

export function isStringAndContains(input: any, subString: string): boolean {
  if (isString(input)) {
    return input.indexOf(subString) > -1;
  } else {
    return false;
  }
}

export function isNilOrWhiteSpaceHTML(value: string): boolean {
  if (typeof value === 'string') {
    value = value.replace(/<br>/g, '');
    value = value.replace(/<p>/g, '');
    value = value.replace(new RegExp('</p>', 'g'), '');
  }
  return isNilOrWhiteSpace(value);
}

export function isValidEmail(email: string): boolean {
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}

export function toPercentageString(fraction: number): string {
  if (
    fraction === null ||
    fraction === undefined ||
    isNaN(fraction) ||
    !isNumber(fraction) ||
    fraction === -1
  )
    return '';
  return Math.round(fraction * 100).toString() + '%';
}

export function removeAllSpacees(value: string): string {
  if (isNilOrWhiteSpace(value)) return value;
  return value.replace(/ /g, '');
}

export function removeAllHyphen(value: string): string {
  if (isNilOrWhiteSpace(value)) return value;
  return value.replace(/-/g, '');
}

export function firstWord(value: string): string {
  if (!value) {
    return value;
  }
  const words = value.split(' ');
  if (words.length > 0) {
    return words[0];
  } else {
    return '';
  }
}

export function normalizeApostrophes(input: string): string {
  if (input) {
    input = input.replace(apostrophesRegex, '\'');
  }
  return input;
}

export function hasOneCharacterDifferent(
  input1: string,
  input2: string
): boolean {
  if (input1 && input2) {
    input1 = normalizeApostrophes(input1);
    input2 = normalizeApostrophes(input2);

    if (input1 !== input2) {
      const answerArray = input1.toLowerCase().split('');
      const phraseArray = input2.toLowerCase().split('');
      const wrong = sum(
        zip(answerArray, phraseArray).map((pair) => pair[0] !== pair[1])
      );
      return wrong === 1;
    }
  }
  return false;
}

export function hasTwoCharactersSwapped(
  input1: string,
  input2: string
): boolean {
  if (input1 && input2 && input1.length === input2.length) {
    input1 = normalizeApostrophes(input1);
    input2 = normalizeApostrophes(input2);

    if (input1 !== input2) {
      const answerArray = input1.toLowerCase().split('');
      const phraseArray = input2.toLowerCase().split('');

      const misplacedIndice: number[] = [];
      for (let index = 0; index < answerArray.length; index++) {
        const answerLetter = answerArray[index];
        const phraseLetter = phraseArray[index];
        if (answerLetter !== phraseLetter) {
          misplacedIndice.push(index);
        }
      }

      if (misplacedIndice.length === 2) {
        if (
          answerArray[misplacedIndice[0]] === phraseArray[misplacedIndice[1]] &&
          answerArray[misplacedIndice[1]] === phraseArray[misplacedIndice[0]]
        ) {
          return true;
        }
      }
    }
  }
  return false;
}

export function isCapital(input: string) {
  if (input) {
    return input.toLowerCase() !== input;
  } else {
    return null;
  }
}

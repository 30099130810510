import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../configuration';
import { HttpClientService } from '../../core/http-client.service';
import { HallOfFame } from '../../model/hall-of-fame';

@Injectable()
export class HallOfFameService{

  baseUrl : string;

  constructor(private http: HttpClientService, config : Configuration){
    this.baseUrl = `${config.apiRootUrl}/school/hallOfFames`;
  }

  getHallOfFame(schoolId : string): Observable<HallOfFame>{
    const url = `${this.baseUrl}/${schoolId}`;
    return this.http.authGet<HallOfFame>(url);
  }
}
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { Configuration } from 'src/app/configuration';
import { UpdateLevelsCommand } from 'src/app/model/update-levels-command';
import { AppState } from 'src/app/states/states-reducers';
import { UpdateVocabLevelsCommand } from 'src/app/vocab/vocab-shared/update-vocab-levels-command';
import { HttpClientService } from '../http-client.service';
import { LoggingService } from '../log/logging.service';
import { StorageService } from '../storage.service';
import { MessageDisplayService } from '../ui/message-display.service';
import { hideSpinner } from '../ui/ui-actions';

export abstract class ExerciseSubmissionServiceBase {
  protected _updateTimedOut = new BehaviorSubject<boolean>(undefined);
  private retry = 0;
  private _timeout: any;
  constructor(
    protected http: HttpClientService,
    protected config: Configuration,
    protected localStorageService: StorageService,
    protected store: Store<AppState>,
    protected messageDisplayService: MessageDisplayService,
    protected loggingService : LoggingService
  ) {}

  get updateIsTimedOut$(): Observable<boolean> {
    return this._updateTimedOut.asObservable();
  }

  startSubmissionRetry(commandSubjectPrefix: string) {
    this.retry = 0;
    this.clearSubmissionVerificationInterval();
    this.tryDispatchStoredCommands(commandSubjectPrefix).then(
      (hasCommandDispatched) => {
        if (hasCommandDispatched) {
          this.setSubmissionVerificationInterval(commandSubjectPrefix);
        } else {
          this.stopAndResetRetry();
        }
      }
    );
  }

  isValidUpdateCommand(command : UpdateLevelsCommand) : boolean{
    return !!command && !!command.levels && command.levels.length > 0;
  }

  isValidUpdateVocabLevelsCommand(command : UpdateVocabLevelsCommand) : boolean{
    return !!command && !!command.levels && command.levels.length > 0 && !!command.nonce;
  }

  protected abstract sendCommandUsingCommandStored(
    commandKey: string
  ): Promise<any>;

  private stopAndResetRetry() {
    this.clearSubmissionVerificationInterval();
    this.store.dispatch(hideSpinner());
    this.retry = 0;
    this._updateTimedOut.next(false);
  }

  private tryDispatchStoredCommands(
    commandSubjectPrefix: string
  ): Promise<boolean> {
    return this.getCommandKeys(commandSubjectPrefix).then((keys) => {
      if (keys.length > 0) {
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          this.sendCommandUsingCommandStored(key);
        }
        return true;
      } else {
        return false;
      }
    });
  }

  private clearSubmissionVerificationInterval() {
    clearInterval(this._timeout);
  }

  private getCommandKeys(commandSubjectPrefix: string): Promise<string[]> {
    return this.localStorageService.tryGetAllKeys().then((keys) => {
      return keys.filter((k) => k.startsWith(commandSubjectPrefix));
    });
  }

  private setSubmissionVerificationInterval(commandSubjectPrefix: string) {
    this._timeout = setInterval(() => {
      this.verifySubmission(commandSubjectPrefix);
    }, this.config.exerciseSubmissionVerificationIntervalInMs);
  }

  private verifySubmission(commandSubjectPrefix: string) {
    this.tryDispatchStoredCommands(commandSubjectPrefix).then(
      (hasCommandDispatched) => {
        if (hasCommandDispatched) {
          this.retry++;
          if (this.retry > this.config.exerciseSubmissionRetryLimits) {
            this._updateTimedOut.next(true);
            this.store.dispatch(hideSpinner());
          }
        } else {
          this.stopAndResetRetry();
          this.messageDisplayService.displayDataSynchronizedMessage();
        }
      }
    );
  }
}

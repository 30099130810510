import { Injectable } from '@angular/core';
import { replace, sum } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { HttpClientService } from '../core/http-client.service';
import { CustomReportAttributes } from '../model/custom-report-attributes';
import { Language } from '../model/language';
import { MentorMentee } from '../model/mentor-mentee';
import { OperationResult } from '../model/operation-result';
import { PushNotification } from '../model/push-notification';
import { School } from '../model/school';
import { SchoolAdminRegistration } from '../model/school-admin-registration';
import { SchoolPreference } from '../model/school-preference';
import { SchoolUser } from '../model/school-user';
import {
  IStudentParticipation,
  StudentParticipation
} from '../model/student-partcipation';
import { OverridePasswordCommand } from '../system/override-password-command';
import { SchoolBasedVocabularySet } from './school-based-vocabulary-set';
import { VocabularySetAssignment } from './vocabulary-set-assignment';

@Injectable({ providedIn: 'root' })
export class SchoolService {
  baseUrl: string;
  constructor(
    private httpService: HttpClientService,
    private config: Configuration
  ) {
    this.baseUrl = this.config.apiRootUrl + '/school';
  }

  assignVocabularySetFromStudents(
    vocabularySet: SchoolBasedVocabularySet,
    subjects: string[]
  ) {
    const body: VocabularySetAssignment = {
      schoolId: vocabularySet.schoolId,
      name: vocabularySet.name,
      language: vocabularySet.language,
      subjects
    };
    const url = `${this.baseUrl}/schoolBasedVocabularySets/remove`;
    return this.httpService.authPost(url, body);
  }

  assignVocabularySetToStudents(
    vocabularySet: SchoolBasedVocabularySet,
    subjects: string[]
  ) {
    const body: VocabularySetAssignment = {
      schoolId: vocabularySet.schoolId,
      name: vocabularySet.name,
      language: vocabularySet.language,
      subjects
    };
    const url = `${this.baseUrl}/schoolBasedVocabularySets/assign`;
    return this.httpService.authPost(url, body);
  }

  updateSchoolBasedVocabularySet(
    vocabularySet: SchoolBasedVocabularySet
  ): Observable<SchoolBasedVocabularySet> {
    const url = `${this.baseUrl}/schoolBasedVocabularySets`;
    return this.httpService.authPatch<SchoolBasedVocabularySet>(
      url,
      vocabularySet
    );
  }

  deleteSchoolBasedVocabularySet(
    vocabularySet: SchoolBasedVocabularySet
  ): Observable<any> {
    const url = `${this.baseUrl}/schoolBasedVocabularySets/${vocabularySet.schoolId}/${vocabularySet.language}/${vocabularySet.name}`;
    return this.httpService.authDelete(url);
  }

  getVocabularySetsBySchoolId(
    schoolId: string
  ): Observable<SchoolBasedVocabularySet[]> {
    const url = `${this.baseUrl}/schoolBasedVocabularySetsCollection/${schoolId}`;
    return this.httpService.authGet<SchoolBasedVocabularySet[]>(url);
  }

  createSchoolBasedVocabularySet(
    schoolId: string,
    name: string,
    remark: string
  ): Observable<SchoolBasedVocabularySet> {
    const url = `${this.baseUrl}/schoolBasedVocabularySets`;
    const body: SchoolBasedVocabularySet = {
      schoolId,
      name,
      remark,
      language: Language.English,
      words: []
    };
    return this.httpService.authPost(url, body);
  }

  deleteUserVocabEntriesAndCreateBackup(
    subjects: string[],
    schoolId: string,
    syllabusId: string,
    unattemptedOnly: boolean
  ): Observable<any> {
    const url = `${this.baseUrl}/vocabularyBackups`;
    return this.httpService.authPost(url, {
      subjects,
      schoolId,
      syllabusId,
      unattemptedOnly
    });
  }

  restoreVocabularyFromBackup(id: string, schoolId: string): Observable<any> {
    const url = `${this.baseUrl}/vocabularyBackups/${id}/${schoolId}`;
    return this.httpService.authPut(url, {});
  }

  createSchoolAdminRegistration(
    schoolAdminRegistration: SchoolAdminRegistration
  ): Observable<boolean> {
    const url: string = `${this.baseUrl}/schoolAdminRegistrations`;
    return this.httpService
      .authPost<OperationResult>(url, schoolAdminRegistration)
      .pipe(map((r) => r.success));
  }

  getSchool(schoolId: string): Observable<School> {
    const url = `${this.baseUrl}/schools/${schoolId}`;
    return this.httpService.authGet<School>(url);
  }

  getSchoolAdminRegistrations(
    schoolId: string
  ): Observable<SchoolAdminRegistration[]> {
    const url: string = `${this.baseUrl}/schoolAdminRegistrations/${schoolId}`;
    return this.httpService.authGet<SchoolAdminRegistration[]>(url);
  }

  getSchoolUsers({
    schoolId,
    mostRecentOnly
  }: {
    schoolId: string;
    mostRecentOnly: boolean;
  }): Observable<SchoolUser[]> {
    const url: string = `${this.baseUrl}/schoolUsers/${schoolId}?mostRecentOnly=${mostRecentOnly}`;
    return this.httpService.authGet(url);
  }

  getSchoolUser(schoolId: string, userSubject: string): Observable<SchoolUser> {
    const url: string = `${this.baseUrl}/schoolUsers/${schoolId}/${userSubject}`;
    return this.httpService.authGet(url);
  }

  getSchoolsWithSchoolAdminRights(): Observable<School[]> {
    const url: string = `${this.baseUrl}/schools?admin=true`;
    return this.httpService
      .authGet<School[]>(url)
      .pipe(map((schools) => schools.map(this.mapSchool)));
  }

  mapSchool(school: School): School {
    school.invoiced = new Date(school.invoiced);
    school.paymentReceived = new Date(school.paymentReceived);
    school.receiptIssued = new Date(school.receiptIssued);
    school.nextMeeting = new Date(school.nextMeeting);
    school.nextTraining = new Date(school.nextTraining);

    return school;
  }

  updateSchoolAdminRegistration(
    registration: SchoolAdminRegistration
  ): Observable<OperationResult> {
    const url: string = `${this.baseUrl}/schoolAdminRegistrations`;
    return this.httpService.authPatch(url, registration);
  }

  revokeRegistrationCode(registrationCode: string): Observable<boolean> {
    registrationCode = replace(registrationCode, /-/g, '');
    const url: string = `${this.baseUrl}/registrationCodes/${registrationCode}`;
    return this.httpService.authPatch(url, null).pipe(map((_) => true));
  }

  getMentorMentees(schoolId: string): Observable<MentorMentee[]> {
    const url: string = `${this.baseUrl}/mentorMentees/${schoolId}`;
    return this.httpService.authGet<MentorMentee[]>(url);
  }

  linkUpSchoolAdminRegistration(dto: any): Observable<any> {
    const url: string = `${this.baseUrl}/schoolAdminRegistrationConfirmations`;
    return this.httpService.authPost(url, dto);
  }

  linkUpSchoolUserRegistration(dto: any): Observable<any> {
    const url: string = `${this.baseUrl}/schoolUserRegistrationConfirmations`;
    return this.httpService.authPost(url, dto);
  }

  downloadRegistrationCodesInPdf(dto: any) {
    const url: string = `${this.baseUrl}/registrationCodePdfs`;
    return this.httpService.authPost(url, dto);
  }

  pushNotification({
    notification,
    schoolId
  }: {
    notification: PushNotification;
    schoolId: string;
  }) {
    const url: string = `${this.baseUrl}/notifications?schoolId=${schoolId}`;
    return this.httpService.authPost(url, notification);
  }

  overridePassword(command: OverridePasswordCommand): Observable<boolean> {
    const url: string =
      this.config.apiRootUrl + '/school/passwords?schoolId=' + command.schoolId;
    return this.httpService
      .authPatch<OperationResult>(url, command)
      .pipe(map((r) => r.success));
  }

  deleteSchoolAdministratorRegistration(
    registrationCode: string,
    schoolId: string
  ): Observable<any> {
    const url: string = `${this.baseUrl}/schoolAdminRegistrations/${registrationCode}?schoolId=${schoolId}`;
    return this.httpService.authDelete<SchoolAdminRegistration[]>(url);
  }

  updateSchool(
    schoolId: string,
    op: string,
    path: string,
    value: any
  ): Observable<any> {
    const patchDocument = { op, path, value };
    const url = `${this.baseUrl}/schools/${schoolId}`;
    return this.httpService.authPatch(url, [patchDocument]);
  }

  upsertSchool(school: School): Observable<any> {
    const url = `${this.baseUrl}/schools/${school.id}`;
    return this.httpService.authPut(url, school);
  }

  upsertCustomReportAttributes(
    customReportAttributes: CustomReportAttributes
  ): Observable<any> {
    const url = `${this.baseUrl}/customReportAttributesCollection`;
    return this.httpService.authPost(url, customReportAttributes);
  }

  getCustomReportAttributes(
    schoolId: string
  ): Observable<CustomReportAttributes> {
    const url = `${this.baseUrl}/customReportAttributesCollection/${schoolId}`;
    return this.httpService.authGet<CustomReportAttributes>(url);
  }

  publishEnableThrottlingEvents(schoolId: string): Observable<any> {
    const url = `${this.baseUrl}/schoolUsers/${schoolId}`;
    return this.httpService.authPut(url, {});
  }

  getStudentParticipations(payload: any): Observable<StudentParticipation[]> {
    const url = `${this.config.apiRootUrl}/school/batchDataExports/studentParticipations`;
    return this.httpService
      .authPost<IStudentParticipation[]>(url, payload)
      .pipe(
        map((values) => values.map((value) => new StudentParticipation(value))),
        map((values) => {
          values.forEach(
            (v) => (v.groupAverageScore = this.getGroupAverageScore(values, v))
          );
          return values;
        })
      );
  }

  getSchoolPreference(schoolId: string): Observable<SchoolPreference> {
    const url = `${this.config.apiRootUrl}/school/schoolPreferences/${schoolId}`;
    return this.httpService.authGet<SchoolPreference>(url);
  }

  updateSchoolPreference(
    schoolPreference: SchoolPreference
  ): Observable<OperationResult> {
    const url = `${this.config.apiRootUrl}/school/schoolPreferences`;
    return this.httpService.authPost(url, schoolPreference);
  }

  getGroupAverageScore(
    participations: StudentParticipation[],
    participation: StudentParticipation
  ): number {
    if (participation.groupAbility === 0) {
      return undefined;
    }
    const partitionsOfGroupsWithSameAbilities = participations.filter(
      (p) => p.groupAbility === participation.groupAbility
    );

    const sumOfUserCount = sum(
      partitionsOfGroupsWithSameAbilities.map((p) => p.userCount)
    );
    if (sumOfUserCount === 0) {
      return undefined;
    }

    const sumOfTotalScore = sum(
      partitionsOfGroupsWithSameAbilities.map((p) => p.totalScore)
    );

    return sumOfTotalScore / sumOfUserCount;
  }
}

import { NgModule } from '@angular/core';
import { RichTextHtmlEditorModule } from '../rich-text-html-editor/rich-text-html-editor.module';
import { SharedModule } from '../shared/shared.module';
import { AlternativeCharacterComponent } from './alternative-character.component';
import { AnswerInputComponent } from './answer-input.component';
import { ChinesePronunciationDisplayComponent } from './chinese-pronunciation/chinese-pronunciation-display.component';
import { ChinesePronunciationEditComponent } from './chinese-pronunciation/chinese-pronunciation-edit.component';
import { TranslationComponent as CloudTranslationComponent } from './cloud-translation/cloud-translation.component';
import { DefinitionOrderToggleComponent } from './definition-order-toggle.component';
import { DictationDisplayComponent } from './dictation/dictation-display.component';
import { DictationEditComponent } from './dictation/dictation-edit.component';
import { DefinitionEditComponent } from './dictionary-ui/definition-edit.component';
import { DefinitionsComponent } from './dictionary-ui/definitions.component';
import { DictEntriesEditComponent } from './dictionary-ui/dict-entries-edit.component';
import { DictEntriesComponent } from './dictionary-ui/dict-entries.component';
import { DictEntryEditComponent } from './dictionary-ui/dict-entry-edit-component';
import { DictEntryComponent } from './dictionary-ui/dict-entry.component';
import { ExampleListComponent } from './dictionary-ui/example-list.component';
import { HomonymsComponent } from './dictionary-ui/homonyms.component';
import { PhoneticSymbolComponent } from './dictionary-ui/phonetic-symbol.component';
import { PhoneticsContainerComponent } from './dictionary-ui/phonetics-container.component';
import { PhoneticsComponent } from './dictionary-ui/phonetics.component';
import { RemarkComponent } from './dictionary-ui/remark.component';
import { StrokeDisplayComponent } from './dictionary-ui/stroke-display.component';
import { DisplayBaseButtonsComponent } from './display-base-buttons.component';
import { EditAnswerIsCaseSensitiveComponent } from './edit-answer-is-case-sensitive.component';
import { EditAnswerComponent } from './edit-answer.component';
import { EditAnswersComponent } from './edit-answers.component';
import { EditBaseButtonsComponent as EditSaveResetButtonsComponent } from './edit-base-buttons.component';
import { EditCommentComponent } from './edit-comment.component';
import { EditCommonAttributesComponent } from './edit-common-attributes.component';
import { EditCorrectChoicesComponent } from './edit-correct-choices.component';
import { EditExplanationComponent } from './edit-explanation.component';
import { EditHintComponent } from './edit-hint.component';
import { EditIncorrectChoicesComponent } from './edit-incorrect-choices.component';
import { EditPendingIssueComponent } from './edit-pending-issue.component';
import { EditPhraseComponent } from './edit-phrase.component';
import { EditPronounceAnswerOnAnswerShowComponent } from './edit-pronounce-answer-on-answer-show.componet';
import { EditPronounceAnswerOnQuestionShowComponent } from './edit-pronounce-answer-on-question-show.componet';
import { EditQuestionsComponent } from './edit-question-markdowns.component';
import { EditQuestionOrderComponent } from './edit-question-order.component';
import { EditRequireCalculatorComponent } from './edit-require-calculator.component';
import { EditSentenceComponent } from './edit-sentence.component';
import { EditTagsComponent } from './edit-tags.component';
import { McDisplayComponent } from './mc/mc-display.component';
import { McEditComponent } from './mc/mc-edit.component';
import { NumeralDisplayComponent } from './numeral/numeral-display.component';
import { NumeralEditComponent } from './numeral/numeral-edit.component';
import { PronunciationSelectorComponent } from './pronunciation-ui/pronunciation-selector.component';
import { VocabularyLanguageSelectorComponent } from './pronunciation-ui/vocabulary-language-selector.component';
import { QuestionDisplayComponent } from './question-display.component';
import { QuestionEditComponent } from './question-edit.component';
import { SelectSyllabusLineComponent } from './select-syllabus-line.component';
import { ShortResponseDisplayComponent } from './short-response/short-response-display.component';
import { ShortResponseEditComponent } from './short-response/short-response-edit.component';
import { StrokeDirective } from './stroke-directive';
import { SyllabusExerciseRecordListingComponent } from './syllabus-exercise-record-ui/syllabus-exercise-record-listing.component';
import { SyllabusExerciseRecordsComponent } from './syllabus-exercise-record-ui/syllabus-exercise-records.component';
import { TestRecordListComponent } from './test-records-ui/test-record-list.component';
import { TestRecordsComponent } from './test-records-ui/test-records.component';
import { MediumDisplayDialogComponent } from './thumbnail-ui/medium-display-dialog.component';
import { ThumbnailBandComponent } from './thumbnail-ui/thumbnail-band.component';
import { ThumbnailReportComponent } from './thumbnail-ui/thumbnail-report.component';
import { ThumbnailComponent } from './thumbnail-ui/thumbnail.component';
import { ThumbnailsReportComponent } from './thumbnail-ui/thumbnails-report.component';
import { TrueOrFalseDisplayComponent } from './true-or-false/true-or-false-display.component';
import { TrueOrFalseEditComponent } from './true-or-false/true-or-false-edit.component';
import { UsernameComponent } from './username.component';
import { VocabularyDisplayComponent } from './vocabulary/vocabulary-display.component';
import { VocabularyEditComponent } from './vocabulary/vocabulary-edit.component';

@NgModule({
  imports: [SharedModule, RichTextHtmlEditorModule],
  declarations: [
    UsernameComponent,
    ExampleListComponent,
    QuestionEditComponent,
    QuestionDisplayComponent,
    ThumbnailReportComponent,
    ThumbnailsReportComponent,
    PhoneticsContainerComponent,
    StrokeDisplayComponent,
    AnswerInputComponent,
    AlternativeCharacterComponent,
    StrokeDirective,
    MediumDisplayDialogComponent,
    DefinitionOrderToggleComponent,
    PhoneticSymbolComponent,
    EditSentenceComponent,
    EditAnswerComponent,
    NumeralDisplayComponent,
    NumeralEditComponent,
    EditPhraseComponent,
    SelectSyllabusLineComponent,
    DisplayBaseButtonsComponent,
    EditAnswersComponent,
    EditAnswerIsCaseSensitiveComponent,
    EditQuestionOrderComponent,
    EditRequireCalculatorComponent,
    EditPendingIssueComponent,
    EditTagsComponent,
    EditQuestionsComponent,
    EditPronounceAnswerOnQuestionShowComponent,
    EditPronounceAnswerOnAnswerShowComponent,
    EditCommentComponent,
    EditIncorrectChoicesComponent,
    EditHintComponent,
    EditExplanationComponent,
    EditCorrectChoicesComponent,
    EditCommonAttributesComponent,
    EditSaveResetButtonsComponent,
    VocabularyLanguageSelectorComponent,
    DefinitionsComponent,
    DictEntriesComponent,
    DictEntryComponent,
    RemarkComponent,
    PhoneticsComponent,
    ThumbnailBandComponent,
    ThumbnailComponent,
    PronunciationSelectorComponent,
    SyllabusExerciseRecordsComponent,
    SyllabusExerciseRecordListingComponent,
    ShortResponseDisplayComponent,
    ShortResponseEditComponent,
    McDisplayComponent,
    McEditComponent,
    TrueOrFalseDisplayComponent,
    TrueOrFalseEditComponent,
    VocabularyDisplayComponent,
    VocabularyEditComponent,
    TestRecordListComponent,
    TestRecordsComponent,
    CloudTranslationComponent,
    DictationDisplayComponent,
    DictationEditComponent,
    ChinesePronunciationDisplayComponent,
    ChinesePronunciationEditComponent,
    HomonymsComponent,
    DictEntriesEditComponent,
    DictEntryEditComponent,
    DefinitionEditComponent
  ],
  exports: [
    UsernameComponent,
    ExampleListComponent,
    QuestionDisplayComponent,
    QuestionEditComponent,
    ThumbnailsReportComponent,
    ThumbnailReportComponent,
    PhoneticsContainerComponent,
    StrokeDisplayComponent,
    AnswerInputComponent,
    AlternativeCharacterComponent,
    StrokeDirective,
    MediumDisplayDialogComponent,
    DefinitionOrderToggleComponent,
    EditSentenceComponent,
    EditPhraseComponent,
    EditTagsComponent,
    SelectSyllabusLineComponent,
    DisplayBaseButtonsComponent,
    EditQuestionsComponent,
    EditHintComponent,
    EditAnswersComponent,
    EditCorrectChoicesComponent,
    EditIncorrectChoicesComponent,
    EditCommonAttributesComponent,
    VocabularyLanguageSelectorComponent,
    DictEntriesComponent,
    ThumbnailBandComponent,
    PronunciationSelectorComponent,
    SyllabusExerciseRecordsComponent,
    TestRecordsComponent,
    CloudTranslationComponent,
    DictEntriesEditComponent,
    DictEntryEditComponent,
    DefinitionEditComponent
  ],
  providers:[
  ]
})
export class SmartUIModule {}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { some } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mapTo, switchMapTo } from 'rxjs/operators';
import { InAppNotification } from '../../model/in-app-notification';
import { UnsafeAction } from '../../model/unsafe-action';
import { StorageService } from '../storage.service';
import {
  displayNewNotifications,
  displayNotification,
  loadNotificationsInLocalStorageComplete,
  newNotificationsFetched,
  NotificationActionTypes
} from './notification-actions';
import { NotificationService } from './notification.service';

const notificationsKey = 'inAppNotifications';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private storageService: StorageService,
    private notificationService: NotificationService
  ) {}

  @Effect()
  fetchAndDisplayNewNotifications = this.actions$.pipe(
    ofType(NotificationActionTypes.FETCH_AND_DISPLAY_NEW_NOTIFICATIONS),
    exhaustMap((_: UnsafeAction) =>
      this.notificationService
        .getInAppNotifications()
        .pipe(catchError((_) => of([])))
    ),
    map((notifications: InAppNotification[]) =>
      newNotificationsFetched(notifications)
    )
  );

  @Effect()
  newNotificationsFetched = this.actions$.pipe(
    ofType(NotificationActionTypes.NEW_NOTIFICATIONS_FETCHED),
    exhaustMap((action: UnsafeAction) =>
      this.upsertNewNotificationsIntoLocalStorage(action.payload)
    ),
    map((notifications) => {
      this.unsubscribeNotifications(notifications);
      return displayNewNotifications();
    })
  );

  @Effect()
  displayNewNotifications = this.actions$.pipe(
    ofType(NotificationActionTypes.DISPLAY_NEW_NOTIFICATIONS),
    exhaustMap((_: UnsafeAction) =>
      this.getUndisplayedNotificationInLocalStorage()
    ),
    map((notification) => {
      return displayNotification(notification);
    })
  );

  @Effect()
  dismissNotification = this.actions$.pipe(
    ofType(NotificationActionTypes.DISMISS_NOTIFICATION),
    exhaustMap((action: UnsafeAction) =>
      this.markNotificationAsDisplayed(action.payload)
    ),
    mapTo(displayNewNotifications())
  );

  @Effect()
  displayNotificationsInLocalStorage = this.actions$.pipe(
    ofType(NotificationActionTypes.DISPLAY_NOTIFICATIONS_IN_LOCAL_STORAGE),
    switchMapTo (this.storageService.tryGet(notificationsKey)),
    map((notifications) =>
      loadNotificationsInLocalStorageComplete(notifications)
    )
  );

  @Effect()
  deleteNotificationInLocalStorage = this.actions$.pipe(
    ofType(NotificationActionTypes.DELETE_NOTIFICATION_IN_LOCAL_STORAGE),
    exhaustMap((action: UnsafeAction) =>
      this.deleteNotificationInStorage(action.payload)
    ),
    map((notifications) =>
      loadNotificationsInLocalStorageComplete(notifications)
    )
  );

  async deleteNotificationInStorage(
    notification: InAppNotification
  ): Promise<InAppNotification[]> {
    let existingNotifications: InAppNotification[] =
      await this.getExistingNotifications();
    existingNotifications = existingNotifications.filter(
      (n) => n.id !== notification.id
    );
    await this.storageService.tryPut(notificationsKey, existingNotifications);
    return existingNotifications;
  }

  async getUndisplayedNotificationInLocalStorage(): Promise<InAppNotification> {
    const existingNotifications: InAppNotification[] =
      await this.getExistingNotifications();
    const undisplayedNotifications = existingNotifications.filter(
      (n) => !n.displayed
    );
    if (undisplayedNotifications.length === 0) {
      return null;
    } else {
      return undisplayedNotifications[0];
    }
  }

  async getExistingNotifications(): Promise<InAppNotification[]> {
    const values = await this.storageService.tryGet(notificationsKey);
    if (Array.isArray(values)) {
      return values;
    } else {
      return [];
    }
  }

  async markNotificationAsDisplayed(notification: InAppNotification) {
    const existingNotifications: InAppNotification[] =
      await this.getExistingNotifications();
    for (const existingNotification of existingNotifications) {
      if (notification.id === existingNotification.id) {
        existingNotification.displayed = true;
      }
    }
    await this.storageService.tryPut(notificationsKey, existingNotifications);
  }

  async upsertNewNotificationsIntoLocalStorage(
    notifications: InAppNotification[]
  ): Promise<InAppNotification[]> {
    let existingNotifications: InAppNotification[] =
      await this.getExistingNotifications();
    if (!existingNotifications) {
      existingNotifications = notifications;
    } else {
      for (const notification of notifications) {
        if (
          !some(
            existingNotifications,
            (n: InAppNotification) => n.id === notification.id
          )
        ) {
          existingNotifications.push(notification);
        }
      }
    }
    await this.storageService.tryPut(notificationsKey, existingNotifications);
    return notifications;
  }

  unsubscribeNotifications(notifications: InAppNotification[]): Promise<any> {
    return this.notificationService
      .unsubscribeNotifications(notifications.map((n) => n.id))
      .toPromise();
  }
}

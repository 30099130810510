import { Injectable } from '@angular/core';
import { Configuration } from '../configuration';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class UserStorageService {
  constructor(
    private localStorageService: StorageService,
    private config: Configuration
  ) {}

  putIanaCode(ianaCode: string): Promise<any> {
    return this.localStorageService.tryPut(
      this.config.uiCodeSelectedStorageKey,
      ianaCode
    );
  }

  getIanaCode(): Promise<string> {
    return this.localStorageService
      .tryGet(this.config.uiCodeSelectedStorageKey)
      .then((value) => {
        if (value && value.value) {
          return value.value;
        } else {
          return value;
        }
      });
  }

  onboardingOnceShown(): Promise<boolean> {
    return this.localStorageService
      .tryGet(this.config.onBoardingOnceShownStorageKey)
      .then((value) => !!value);
  }

  setOnBoardingShown(): Promise<any> {
    return this.localStorageService.tryPut(
      this.config.onBoardingOnceShownStorageKey,
      true
    );
  }
}

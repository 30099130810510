import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-img-cropper';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { AccountSettingsButtonComponent } from './account-settings-button.component';
import { AmountComponent } from './amount.component';
import { AmountPipe } from './amount.pipe';
import { AnswerFieldComponent } from './answer-field.component';
import { AnswerNextComponent } from './answer-next.component';
import { ClickableComponent } from './clickable.component';
import { CommaSeparatedWordsComponent } from './comma-separated-words.component';
import { DeferredRecordSynchronizationMessageComponent } from './deferred-record-synchronization-message.component';
import { DictionaryLevelSelectorComponent } from './dictionary-level-selector.component';
import { DisplayModule } from './display/display.module';
import { EmailAddressComponent } from './email-address.component';
import { EscapeHtmlPipe } from './escape-html.pipe';
import { ExaminationBoardComponent } from './examiniation-board.component';
import { FaqButtonComponent } from './faq-button.component';
import { FieldsDisplayComponent } from './fields-display.component';
import { FieldsEditComponent } from './fields-edit.component';
import { FooterButtonsComponent } from './footer-buttons.component';
import { FullStopPipe } from './fullstop.pipe';
import { GoHomeButtonComponent } from './go-home-button.component';
import { IconButtonComponent } from './icon-button.component';
import { ImageScannerComponent } from './image-scanner.component';
import { ImgCropperComponent } from './img-cropper.component';
import { KeyValuePairsEditorComponent } from './key-value-pairs-editor.component';
import { LanguageComponent } from './language.component';
import { MaskPipe } from './mask.pipe';
import { NavigationModule } from './navigation/navigation.module';
import { OcrScannerComponent } from './ocr-scanner.component';
import { OperatorComponent } from './operator.component';
import { RegisterForAFreeConsultationButtonComponent } from './register-for-a-free-consultation-button.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SendButtonComponent } from './send-button';
import { SentenceListComponent } from './sentence-list.component';
import { DatePickerComponent } from './shared-ui/date-picker.component';
import { SharedUiModule } from './shared-ui/shared-ui.module';
import { StudentAverageAcquisitionComponent } from './shared-ui/student-average-acquisition.component';
import { VocabularySizeAndDSEResultComponent } from './shared-ui/vocabulary-size-and-DSE-result.component';
import { SmallUpgradeButtonComponent } from './small-upgrade-button.component';
import { StringsEditorComponent } from './strings-editor.component';
import { SupportButtonComponent } from './support-button.component';
import { SyllabusNameComponent } from './syllabus-name-component';
import { TextScannerComponent } from './text-scanner.component';
import { ToPairPipe } from './to-pair.pipe';
import { UpgradableDirective } from './upgradable.directive';
import { WordListComponent } from './word-list.component';

@NgModule({
  imports: [
    ImageCropperModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PrimeNgModule,
    NavigationModule,
    DisplayModule,
    SharedUiModule,
    TranslateModule.forChild()
  ],
  declarations: [
    StudentAverageAcquisitionComponent,

    DatePickerComponent,
    SupportButtonComponent,
    ToPairPipe,
    FieldsEditComponent,
    FieldsDisplayComponent,
    DeferredRecordSynchronizationMessageComponent,
    EmailAddressComponent,
    ImageScannerComponent,
    SafeHtmlPipe,
    ImgCropperComponent,
    ClickableComponent,
    CommaSeparatedWordsComponent,
    UpgradableDirective,
    SmallUpgradeButtonComponent,
    SendButtonComponent,
    RegisterForAFreeConsultationButtonComponent,
    AccountSettingsButtonComponent,
    AmountPipe,
    GoHomeButtonComponent,
    FaqButtonComponent,
    FooterButtonsComponent,
    KeyValuePairsEditorComponent,
    SyllabusNameComponent,
    DictionaryLevelSelectorComponent,
    LanguageComponent,
    ExaminationBoardComponent,
    AmountComponent,
    AnswerFieldComponent,
    SentenceListComponent,
    WordListComponent,
    MaskPipe,
    EscapeHtmlPipe,
    FullStopPipe,
    IconButtonComponent,
    OperatorComponent,
    AnswerNextComponent,
    OcrScannerComponent,
    TextScannerComponent,
    StringsEditorComponent,
    VocabularySizeAndDSEResultComponent
  ],
  exports: [
    VocabularySizeAndDSEResultComponent,
    StudentAverageAcquisitionComponent,
    DatePickerComponent,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    PrimeNgModule,
    TranslateModule,
    DisplayModule,
    NavigationModule,
    SharedUiModule,
    SupportButtonComponent,
    ToPairPipe,
    CommaSeparatedWordsComponent,
    FieldsEditComponent,
    FieldsDisplayComponent,
    DeferredRecordSynchronizationMessageComponent,
    EmailAddressComponent,
    ImageScannerComponent,
    SafeHtmlPipe,
    TextScannerComponent,
    ImgCropperComponent,
    OcrScannerComponent,
    ClickableComponent,
    SmallUpgradeButtonComponent,
    SendButtonComponent,
    RegisterForAFreeConsultationButtonComponent,
    AccountSettingsButtonComponent,
    AmountPipe,
    GoHomeButtonComponent,
    FaqButtonComponent,
    FooterButtonsComponent,
    KeyValuePairsEditorComponent,
    SyllabusNameComponent,
    DictionaryLevelSelectorComponent,
    LanguageComponent,
    ExaminationBoardComponent,
    AmountComponent,
    AnswerFieldComponent,
    SentenceListComponent,
    WordListComponent,
    MaskPipe,
    EscapeHtmlPipe,
    FullStopPipe,
    IconButtonComponent,
    OperatorComponent,
    AnswerNextComponent,
    StringsEditorComponent,
    UpgradableDirective
  ],

})
export class SharedModule {}

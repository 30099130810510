import { Injectable } from '@angular/core';
import { generateUUID } from '../utility/string-utility';
import { IStorageService } from './IStorageService';

@Injectable({ providedIn: 'root' })
export class SessionStorageService implements IStorageService {
  async tryPut(key: string, item: any): Promise<void> {
    const json = JSON.stringify(item);
    return sessionStorage.setItem(key, json);
  }

  async tryGet(key: string): Promise<any> {
    const json =  sessionStorage.getItem(key);
    return JSON.parse(json);
  }

  async tryGetAllKeys(): Promise<string[]> {
    return Object.keys(sessionStorage);
  }

  async tryRemove(key: string): Promise<void> {
    return sessionStorage.removeItem(key);
  }

  async test(): Promise<boolean> {
    const key = 'sessionStorage';
    const value = generateUUID();
    await this.tryPut(key, value);
    const result = await this.tryGet(key);
    return result === value;
  }
}





import { isNil } from 'lodash';

export interface IStudentParticipation {
  groupName : string;
  groupAbility: number;
  userCount: number;
  days:  number;
  participationRate: number;
  totalScore: number;
  scoreReachingRegularSchoolDaysInAYear: number;
}

export class StudentParticipation implements IStudentParticipation {
  constructor(value: IStudentParticipation){
    this.groupName = value.groupName;
    this.groupAbility = value.groupAbility;
    this.userCount = value.userCount;
    this.days = value.days;
    this.participationRate = value.participationRate;
    this.totalScore = value.totalScore;
    this.scoreReachingRegularSchoolDaysInAYear = value.scoreReachingRegularSchoolDaysInAYear;
  }

  groupName : string;
  groupAbility: number;
  userCount: number;
  days:  number;
  participationRate: number;
  totalScore: number;
  scoreReachingRegularSchoolDaysInAYear: number;
  groupAverageScore : number;


  get averageScore() : number {
    return this.userCount > 0 ? this.totalScore / this.userCount  : 0;
  }

  get groupPerformance() : string{
    if(isNil(this.groupAverageScore)) return undefined;
    const twoFixed = `(${this.groupAverageScore.toFixed(2).toString()})`;
    if(this.averageScore >= this.groupAverageScore) return `GA ${this.groupAbility} : A ` + twoFixed;
    if(this.averageScore >= this.groupAverageScore * 0.8) return `GA ${this.groupAbility} : B ` + twoFixed;
    if(this.averageScore >= this.groupAverageScore * 0.6) return `GA ${this.groupAbility} : C ` + twoFixed;
    if(this.averageScore >= this.groupAverageScore * 0.4) return `GA ${this.groupAbility} : D ` + twoFixed;
    return `GA ${this.groupAbility} : E ` + twoFixed;
  }
}
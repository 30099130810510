import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../states/states-reducers';
import { generateUUID } from '../utility/string-utility';
import { ForageStorageService } from './forage-storage.service';
import { IStorageService } from './IStorageService';
import { SessionStorageService } from './session-storage.service';
import { displayLocalStorageWarning } from './ui/ui-actions';

@Injectable({ providedIn: 'root' })
export class StorageService implements IStorageService {
  services: IStorageService[] = [];
  constructor(
    private store: Store<AppState>,
    forage: ForageStorageService,
    session: SessionStorageService
  ) {
    this.services = [forage, session];
    this.test()
      .then((supported) => {
        if (!supported) {
          this.displayUnsupportedMessage();
        }
      })
      .catch(this.displayUnsupportedMessage);
  }

  async tryPut(key: string, item: any): Promise<void> {
    for (let index = 0; index < this.services.length; index++) {
      const service = this.services[index];
      try {
        await service.tryPut(key, item);
        break;
      } catch (error) {
        console.log(error);
        continue;
      }
    }
  }

  async tryGet(key: string): Promise<any> {
    for (let index = 0; index < this.services.length; index++) {
      const service = this.services[index];
      try {
        return await service.tryGet(key);
      } catch (error) {
        console.log(error);
        continue;
      }
    }
    return undefined;
  }

  async tryGetAllKeys(): Promise<string[]> {
    for (let index = 0; index < this.services.length; index++) {
      const service = this.services[index];
      try {
        return await service.tryGetAllKeys();
      } catch (error) {
        console.log(error);
        continue;
      }
    }
    return [];
  }

  async tryRemove(key: string): Promise<void> {
    for (let index = 0; index < this.services.length; index++) {
      const service = this.services[index];
      try {
        await service.tryRemove(key);
      } catch (error) {
        console.log(error);
        continue;
      }
    }
  }

  async test(): Promise<boolean> {
    const key = 'modernizr';
    await this.tryRemove(key);
    const value = generateUUID();
    await this.tryPut(key, value);
    const result = await this.tryGet(key);
    return result === value;
  }

  displayUnsupportedMessage() {
    this.store.dispatch(displayLocalStorageWarning());
  }
}

import { Injectable } from '@angular/core';
import { NgForage } from 'ngforage';
import { generateUUID } from '../utility/string-utility';
import { IStorageService } from './IStorageService';

@Injectable({ providedIn: 'root' })
export class ForageStorageService implements IStorageService {
  constructor(private ngf: NgForage) {}

  async tryPut(key: string, item: any): Promise<void> {
    return await this.ngf.setItem(key, item);
  }

  async tryGet(key: string): Promise<any> {
    return await this.ngf.getItem<any>(key);
  }

  async tryGetAllKeys(): Promise<string[]> {
    return await this.ngf.keys();
  }

  async tryRemove(key: string): Promise<void> {
    return await this.ngf.removeItem(key);
  }

  async test(): Promise<boolean> {
    const key = 'forage';
    const value = generateUUID();
    await this.tryPut(key, value);
    const result = await this.tryGet(key);
    return result === value;
  }
}
